// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

/* global ajax_object */

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';
import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
// import ScrollOut from 'scroll-out';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

function ajaxLoad(type) {
  let postsList = document.querySelector('#posts-list');
  let postType = postsList.getAttribute('data-post-type');
  let loop = postsList.getAttribute('data-loop');
  let paged = parseInt(postsList.getAttribute('data-paged'));
  let category = postsList.getAttribute('data-category');
  let taxonomy = postsList.getAttribute('data-tax');
  let postsPerPage = postsList.getAttribute('data-posts-per-page');
  let searchValue = postsList.getAttribute('data-search');
  let view = postsList.getAttribute('data-view');
  let loadBtn = document.querySelector('.js-ajax-load');
  // let loader = document.querySelector('.lds-ellipsis');
  let emptyMessage =
    '<h3 className="posts-list--not-found">No results found</h3>';

  if (!document.body.classList.contains('loading')) {
    document.body.classList.add('loading');
    // loader.classList.add('show');

    let xhr = new XMLHttpRequest();
    xhr.open('POST', ajax_object.ajax_url, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    xhr.onload = function () {
      if (xhr.status >= 200 && xhr.status < 400) {
        let data = JSON.parse(xhr.responseText);

        if (data.type === 'filter' || data.type === 'search') {
          postsList.setAttribute('data-paged', '1');
          postsList.setAttribute('data-view', '');
          let ajaxInput = document.querySelector('.js-ajax-input');
          if (ajaxInput) {
            ajaxInput.value = '';
          }
          if (paged >= data.max_pages) {
            if (loadBtn) loadBtn.style.display = 'none';
          } else {
            if (loadBtn) loadBtn.style.display = 'flex';
          }
          postsList.innerHTML = !data.data ? emptyMessage : data.data;
        } else if (data.type === 'load') {
          ++paged;
          postsList.setAttribute('data-paged', paged.toString());
          postsList.setAttribute('data-max', data.max_pages);
          if (paged >= data.max_pages) {
            loadBtn.style.display = 'none';
          }
          postsList.innerHTML = !data.data
            ? emptyMessage
            : postsList.innerHTML + data.data;
        }

        document.body.classList.remove('loading');
        // loader.classList.remove('show');
      } else {
        console.error('Request failed with status:', xhr.status);
        document.body.classList.remove('loading');
        // loader.classList.remove('show');
      }
    };

    xhr.onerror = function () {
      console.error('An error occurred during the request.');
      document.body.classList.remove('loading');
      // loader.classList.remove('show');
    };

    let data = new URLSearchParams({
      action: 'load',
      posttype: postType,
      type: type,
      loop: loop,
      paged: paged,
      tax: taxonomy,
      cat: category,
      postsperpage: postsPerPage,
      searchvalue: searchValue,
      view: view,
    });

    xhr.send(data);
  }
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  $('.hero-slider').slick({
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
  });

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  // $('.gallery-item')
  //   .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
  //   .attr('rel', 'gallery')
  //   .attr('data-fancybox', 'gallery');
  // $(
  //   '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  // ).fancybox({
  //   minHeight: 0,
  //   helpers: {
  //     overlay: {
  //       locked: false,
  //     },
  //   },
  // });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  $(document).on('gform_confirmation_loaded', function (event, formId) {
    let $target = $('#gform_confirmation_wrapper_' + formId);
    if ($target.length) {
      $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
      return false;
    }
  });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }

  // Handle click event on the button with class 'js-ajax-load'
  let loadButton = document.querySelector('.js-ajax-load button');
  if (loadButton) {
    loadButton.addEventListener('click', function (event) {
      event.preventDefault();
      ajaxLoad('load');
    });
  }

  // Handle click events on category buttons
  let catBtns = document.querySelectorAll('.js-ajax-filter');
  catBtns.forEach(function (catBtn) {
    catBtn.addEventListener('click', function (event) {
      event.preventDefault();
      if (!catBtn.classList.contains('cat_active')) {
        let category = catBtn.getAttribute('data-filter');
        let view = catBtn.getAttribute('data-view');
        let postsList = document.querySelector('#posts-list');
        postsList.setAttribute('data-category', category);
        postsList.setAttribute('data-view', view);
        postsList.setAttribute('data-search', '');
        catBtns.forEach(function (btn) {
          btn.classList.remove('cat_active');
        });
        catBtn.classList.add('cat_active');
        if (!catBtn.classList.contains('fake-filter')) ajaxLoad('filter');
      }
    });
  });

  // Get the search button and input element
  let searchInput = document.querySelector('.js-ajax-input');
  // Attach a 'search' event listener to the search input (for pressing Enter key)
  if (searchInput) {
    searchInput.addEventListener('search', function (event) {
      event.preventDefault(); // Prevent the default form submission

      // Get the search value and postsList element
      let searchValue = this.value;
      let postsList = document.querySelector('#posts-list');

      // Check if the search value is valid
      if (searchValue.length >= 3 || searchValue === '') {
        // Set the search value in the data attribute
        postsList.setAttribute('data-search', searchValue);

        // Call the ajaxLoad function with 'search' type
        ajaxLoad('search');
      }
    });
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
